import styled from "styled-components";

const RootSection = styled.section<{ $pcWidth?: string }>`
  margin: 40px auto 60px;
  @media screen and (max-width: 960px) {
    width: 100%;
    padding: 0 10px;
    min-height: calc(100vh - 70px - 321px - 100px);
  }
  @media screen and (min-width: 961px) {
    width: ${({ $pcWidth }) => $pcWidth ?? "960px"};
    min-height: calc(100vh - 108px - 284px - 100px);
  }
`;
export default RootSection;
