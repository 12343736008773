import { GetStaticProps, NextPage } from "next";
import React from "react";
import { Props as FooterProps } from "components/front/layout/Footer";
import { GeneralFoot } from "components/front/layout/GeneralFoot";
import dynamic from "next/dynamic";
import Main from "components/front/layout/Main";
import RootSection from "components/front/layout/RootSection";
import styled from "styled-components";
import { Props as HeaderProps } from "components/front/layout/Header";
import { Props as NewsSectionProps } from "components/front/corporate/NewsSection";
import { Props as CompanySectionProps } from "components/front/corporate/CompanySection";
import { Props as MissionSectionProps } from "components/front/corporate/MissionSection";
import { Props as ServiceSectionProps } from "components/front/corporate/ServiceSection";
import { Props as ActivitySectionProps } from "components/front/corporate/ActivitySection";
import { Props as HeroAreaProps } from "components/front/corporate/HeroArea";
import { FrontAuthProps, withFrontAuth } from "lib/hoc/withFrontAuth";
import { News } from "interfaces/front/page/corporate";
import { FrontOrderFormService } from "lib/service/client/page/FrontCorporateService";
import { LoadingDarkBg } from "components/front/layout/DarkBackground";
import CorporatePageHead from "components/front/head/CorporatePageHead";

const Header = dynamic<HeaderProps>(() => import("components/front/layout/Header").then((m) => m.Header));
const DarkBackground = dynamic<Record<string, never>>(() => import("components/front/layout/DarkBackground"), {
  ssr: false,
  loading: LoadingDarkBg,
});
const HeroArea = dynamic<HeroAreaProps>(() => import("components/front/corporate/HeroArea"));
const NewsSection = dynamic<NewsSectionProps>(() => import("components/front/corporate/NewsSection"));
const CompanySection = dynamic<CompanySectionProps>(() => import("components/front/corporate/CompanySection"));
const MissionSection = dynamic<MissionSectionProps>(() => import("components/front/corporate/MissionSection"));
const ServiceSection = dynamic<ServiceSectionProps>(() => import("components/front/corporate/ServiceSection"));
const ActivitySection = dynamic<ActivitySectionProps>(() => import("components/front/corporate/ActivitySection"));
const Footer = dynamic<FooterProps>(() => import("components/front/layout/Footer"));

type Props = { newsList: News[] };
const CorporatePage: NextPage<FrontAuthProps & Props> = ({ newsList }) => {
  return (
    <>
      <CorporatePageHead />
      <Header />
      <DarkBackground />
      <Main>
        <StyledHeroArea />
        <StyledRootSection>
          <ServiceSection />
          <NewsSection newsList={newsList} />
          <MissionSection />
          <CompanySection />
          <ActivitySection />
        </StyledRootSection>
      </Main>
      <Footer />
      <GeneralFoot />
    </>
  );
};

export const getStaticProps: GetStaticProps<Props> = async () => {
  const result = await new FrontOrderFormService().getPageData();
  if (result.isSuccess()) {
    return { props: { newsList: result.value.news_list }, revalidate: 60 };
  } else {
    return { props: { newsList: [] }, revalidate: 1 };
  }
};

export default withFrontAuth(CorporatePage);

const StyledHeroArea = styled(HeroArea)`
  margin-bottom: 20px;
  @media screen and (min-width: 961px) {
    margin: 0 auto;
    max-width: 1280px;
  }
`;

const StyledRootSection = styled(RootSection)`
  && {
    width: 100%;
    margin: 0 auto;
  }

  & > section {
    color: white;
    padding-top: 40px;
    padding-bottom: 100px;
  }
`;
