import React, { FC, useMemo } from "react";
import Head from "next/head";
import ResetCss from "components/front/layout/ResetCss";

const TITLE = "アニメファンド株式会社 - コーポレート";
const DESCRIPTION = "アニメ特化クラウドファンディングを開発しているアニメファンドコーポレートサイト";
const URL = `${process.env.API_CF_BASE_URL}/corporate`;

const CorporatePageHead: FC = () => {
  const robotsValue = useMemo(() => (process.env.APP_ENV === "production" ? "all" : "noindex"), []);
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width,initial-scale=1,user-scalable=no" />
        <title key="title">{TITLE}</title>
        <meta name="description" content={DESCRIPTION} />
        <link rel="canonical" href={URL} />
        <meta property="og:title" content={TITLE} />
        <meta property="og:description" content={DESCRIPTION} />
        <meta property="og:image" content={`${process.env.API_CF_BASE_URL}/project-preview/top/animefund_visual_s.jpg`} />
        <meta property="og:url" content={URL} />
        <meta property="og:site_name" content="Animefund" />
        <meta property="og:locale" content="ja_JP" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="robots" content={robotsValue} key="robots" />
      </Head>
      <ResetCss />
    </>
  );
};
export default CorporatePageHead;
