import React from "react";
import styled, { css } from "styled-components";

const Main = styled.div<{ $header?: boolean }>`
  overflow: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  ${({ $header = true }) =>
    $header &&
    css`
      @media screen and (max-width: 960px) {
        padding-top: 70px;
      }
      @media screen and (min-width: 961px) {
        padding-top: 108px;
      }
    `};
`;
export default Main;
