import makeStyles from "@material-ui/core/styles/makeStyles";
import { useDeviceType } from "lib/util";
import React, { FC } from "react";

export const DarkBackground: FC = () => {
  const { pc, sp } = useDeviceType();
  const classes = useStyles();
  return pc ? (
    <div className={classes.background}>
      <div className="bg--item">
        <div className="bg--item_object bg--item_object_01 scrollObject">
          <img src="/img/bg--item_01.png" width="732" height="516" alt="" />
        </div>
        <div className="bg--item_object bg--item_object_02 scrollObject">
          <img src="/img/bg--item_02.png" width="380" height="299" alt="" />
        </div>
        <div className="bg--item_object bg--item_object_03 scrollObject">
          <img src="/img/bg--item_03.png" width="877" height="531" alt="" />
        </div>
        <div className="bg--item_object bg--item_object_04 scrollObject">
          <img src="/img/bg--item_04.png" width="217" height="414" alt="" />
        </div>
        <div className="bg--item_object bg--item_object_05 scrollObject">
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
        </div>
        <div className="bg--item_object bg--item_object_06 scrollObject">
          <img src="/img/bg--item_06.png" width="1108" height="650" alt="" />
        </div>
        <div className="bg--item_object bg--item_object_07 scrollObject">
          <img src="/img/bg--item_07.png" width="569" height="519" alt="" />
        </div>
        <div className="bg--item_object bg--item_object_08 scrollObject">
          <img src="/img/bg--item_08.png" width="380" height="410" alt="" />
        </div>
        <div className="bg--item_object bg--item_object_09 scrollObject">
          <img src="/img/bg--item_09.png" width="662" height="1127" alt="" />
        </div>
        <div className="bg--item_object bg--item_object_10 scrollObject">
          <img src="/img/bg--item_08.png" width="380" height="410" alt="" />
        </div>
        <div className="bg--item_object bg--item_object_11 scrollObject">
          <img src="/img/bg--item_10.png" width="533" height="320" alt="" />
        </div>
        <div className="bg--item_object bg--item_object_12 scrollObject">
          <img src="/img/bg--item_11.png" width="813" height="492" alt="" />
        </div>
      </div>
    </div>
  ) : sp ? (
    <div className={classes.background}>
      <div className="bg--item">
        <div className="bg--item_object bg--item_object_01 scrollObject">
          <img src="/img/bg--item_01--sp.png" width="582" height="382" alt="" />
        </div>
        <div className="bg--item_object bg--item_object_02 scrollObject">
          <img src="/img/bg--item_02--sp.png" width="209" height="265.5" alt="" />
        </div>
        <div className="bg--item_object bg--item_object_03 scrollObject">
          <img src="/img/bg--item_03--sp.png" width="273" height="165" alt="" />
        </div>
        <div className="bg--item_object bg--item_object_04 scrollObject">
          <img src="/img/bg--item_04--sp.png" width="211.5" height="403.5" alt="" />
        </div>
        <div className="bg--item_object bg--item_object_05 scrollObject">
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
          <div className="item" />
        </div>
        <div className="bg--item_object bg--item_object_06 scrollObject">
          <img src="/img/bg--item_06--sp.png" width="531" height="904" alt="" />
        </div>

        <div className="bg--item_object bg--item_object_08 scrollObject">
          <img src="/img/bg--item_04--sp.png" width="211.5" height="403.5" alt="" />
        </div>
        <div className="bg--item_object bg--item_object_09 scrollObject">
          <img src="/img/bg--item_01--sp.png" width="582" height="382" alt="" />
        </div>
      </div>
    </div>
  ) : (
    <LoadingDarkBg />
  );
};
export default DarkBackground;

export const LoadingDarkBg = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.background} />
  );
};

const useStyles = makeStyles(() => ({
  background: {
    position: "absolute",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    top: "0",
    left: "0",
    zIndex: -1,
    backgroundColor: "#101010"
  }
}));
